































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    @Prop() scrollVariable: any;
}
